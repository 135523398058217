import React from "react"
import { graphql } from "gatsby"
import { Flex } from "theme-ui"

import Layout from "../components/layouts"
import { TileImage } from "../components/Page/ContentModules/Tile/elements"
import { NewsletterInput } from "../components/new-ui/newsletter-signup"
import SEO from "../components/seo"

const CopenhagenArchive = ({ data }) => {
  const { tileSection } = data

  return (
    <Layout isSubscribePage almostWhite>
      <SEO
        title="Tekla Archive – Copenhagen"
        description="Register to attend."
        metaImage="https://images.ctfassets.net/4g6al16haqoj/4kn8sZGEdrU7PeHCKMfNXw/5cd7135a13bb6c297958322205d2b8c2/TEKLA_BeddingInspo_Autumn24_4x5_bed_2_04_TEKLA_Bedding_002.jpg"
      />
      {tileSection?.tiles && (
        <Flex
          sx={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: ["column", "row"],
          }}
        >
          {tileSection.tiles?.[0]?.backgroundImage && (
            <Flex sx={{ flex: [null, "0 0 50%"], width: ["100%", "50%"] }}>
              <TileImage
                image={tileSection.tiles[0].backgroundImage.gatsbyImageData}
                alt=""
              />
            </Flex>
          )}
          <Flex
            sx={{
              flexDirection: "column",
              flex: 1,
              alignItems: "center",
              marginTop: ["32px", "0px"],
              paddingX: ["0px", "8px"],
            }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                width: "100%",
                justifyContent: ["flex-start", "center"],
                minHeight: "350px",
                maxWidth: "450px",
                paddingInline: 4,
              }}
            >
              <NewsletterInput
                showDescription={true}
                title={tileSection.displayTitle || ""}
                subtitle={tileSection.displaySubtitle || ""}
                source="main_subscribe_archive_cph"
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Layout>
  )
}

export default CopenhagenArchive

export const query = graphql`
  {
    tileSection: contentfulTileSectionOld(
      title: { eq: "subscribe-copenhagen-archive-tile-section" }
      node_locale: { eq: "en-US" }
    ) {
      title
      displayTitle
      displaySubtitle
      tiles {
        ...Tile
      }
      internal {
        type
      }
    }
  }
`
